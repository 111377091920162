.simple-table .cap-info-table.p-datatable table {
  border-spacing: 0 0;
  border-collapse: collapse;

  thead {
    background: $primary-gradient;
    color: $white;

    tr > th {
      border-left: 1px solid $border-color;
    }
  }

  .th_text-right {
    text-align: right;
  }

  .grey-cell {
    background: #fdfdfd;
    font-weight: 600;
  }

  .underlined {
    border-bottom: 1px solid $table-cell-delimeter-color !important;
  }

  .bold {
    font-weight: bold;
  }

  .p-datatable-thead th,
  .p-datatable-tbody td {
    border: 1px solid #dddddd;
    height: unset;
    padding: 10px;

    &.text-center {
      text-align: center;
    }
  }

  .p-datatable-thead th {
    border-left: 1px solid $border-color;
    font-size: 0.875rem;

    &.th_text-center {
      text-align: center;
    }

    .p-element {
      font-size: 0.875rem;
    }
  }

  td.highlight:not(.grey-cell) {
    background-color: $table-fade-cell-color;
  }

  .p-datatable-tbody {
    tr.cap-table-row-selected {
      & > td {
        &:first-child {
          border-left-color: $border-color;
        }
      }
    }
  }
}

.simple-table.clear-table .cap-info-table.p-datatable table {
  .light-blue {
    background-color: rgba(#028cc7, 3%);
  }

  .p-datatable-tbody td {
    background-color: transparent;
    border: none;
  }
}

.cap-info-table.p-datatable .p-paginator {

  .cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover .p-dropdown-label,
  .cap-dropdown.p-dropdown.p-dropdown .p-dropdown-label {
    padding-right: 0;
  }

  .p-paginator-left-content {
    margin-right: auto;
  }

  .p-paginator-pages .p-paginator-element {
    &:last-child {
      border-right: none;
    }

    &.p-paginator-page {
      color: $default-font-color;
    }

    &.p-highlight {
      background: transparent;
      color: var(--cap-primary-color);
    }

    background: transparent;
    border: none;
  }

  .p-paginator-next:before,
  .p-paginator-prev:before {
    top: 0;
    position: unset;
  }

  .p-paginator-next,
  .p-paginator-prev {
    background: transparent;
    border: none;

    .p-icon-wrapper {
      display: none !important;
    }

    span.pi {
      font-size: 1rem;
    }
  }
}

.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon:before,
.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight.p-focus .p-checkbox-icon:before,
.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover .p-checkbox-icon:before,
.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight.p-focus:not(.p-disabled):hover .p-checkbox-icon:before {
  color: var(--cap-primary-color);
}

.cap-info-table.p-datatable {
  .p-checkbox .p-checkbox-box.p-highlight,
  .p-checkbox .p-checkbox-box.p-highlight.p-focus,
  .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
  .p-checkbox .p-checkbox-box.p-highlight.p-focus:not(.p-disabled):hover {
    border-color: var(--cap-primary-color);
  }

  .th_hidden_column,
  .hidden_column {
    display: none;
    visibility: hidden;
  }
}

.cap-info-table.p-datatable .p-paginator .paginator-left__page-text {
  margin-left: 0;
}
