body cpb-list-renderer {
  .cpb-list-renderer-refresh-btn {
    display: none;
  }

  .filters .filter ::placeholder {
    font-size: 0.875rem;
  }

  .cap-info-table.p-datatable table {
    border-spacing: 0 2px;
  }

  .cap-info-table thead {
    background: $primary-gradient;
    color: $white;
  }

  .cap-info-table.p-datatable thead.p-datatable-thead tr {
    border-width: 1px;
    border-radius: 5px;

    th {
      border-left: 1px solid $border-color;
    }

    & > th:first-child {
      border-top-left-radius: 3px;
    }

    & > th:last-child {
      border-top-right-radius: 3px;
    }
  }

  @media (min-width: 750px) {
    cap-dynamic-filters .cap-filters .filter {
      width: 250px;
    }
  }

  div.cap-table-filter cap-input.has-icon .form-element .p-inputgroup input.p-inputtext {
    min-height: unset;
  }

  .p-datatable .p-sortable-column.p-highlight {
    background: transparent;
  }

  .filters .filter ::placeholder {
    font-size: 1rem;
  }

  .p-paginator .p-paginator-pages .p-paginator-element {
    &:last-child {
      border-right: none;
    }

    &.p-paginator-page {
      color: $default-font-color;
    }

    &.p-highlight {
      background: transparent;
      color: var(--cap-primary-color);
    }

    background: transparent;
    border: none;
  }

  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-prev {
    background: transparent;
    border: none;

    span.pi {
      font-size: 1rem;
    }
  }

  cpb-list-renderer-filters .table-filters {
    align-items: initial !important;
  }

  .cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight,
  .cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: var(--primary-color);

    .p-checkbox-icon:before {
      color: var(--primary-color);
    }
  }

  .row-action__items {
    .row-action__info .title {
      font-weight: 500;
    }

    .row-action__icon .fa-trash-alt {
      color: $red;
    }
  }

  .header {
    background: transparent;
    margin-bottom: 15px;

    .cap-info-table__column-header {
      display: initial;

      cap-input .p-inputgroup {
        margin-bottom: 0 !important;
      }
    }

    .table-filters-items {
      padding-bottom: 0;

      .reset-filter {
        height: 14px;
        top: 10px;
        color: var(--primary-color);
      }
    }
  }

  .content {
    .cap-info-table.p-datatable {
      table {
        border-spacing: 0;
      }

      .p-datatable-thead th {
        padding-top: 3px;
      }

      .p-datatable-thead,
      .p-datatable-tbody {

        th {
          font-weight: 600;
        }

        td .row-action {
          &> i {
            padding: 10px;
            color: var(--primary-color);
          }

          .row-action__items li {
            padding: 9px 12px;
          }
        }

        > cap-info-table-row:last-child tr {
          > td:first-child {
            border-bottom-left-radius: 3px;
          }

          > td:last-child {
            border-bottom-right-radius: 3px;
          }
        }

        td,
        tr.cap-table-row-selected > td {
          border-bottom: 1px solid $border-color;
          height: 2.6rem;
          border-radius: 0;

          &:not(.cap-info-table__column-actions) {
            border-left: 1px solid $border-color;
          }
        }

        td:last-child,
        th:last-child {
          border-right: 1px solid $border-color;
        }

        td:last-child {
          border-top-right-radius: 0;
        }

        tr.cap-table-row-selected > td {
          border-top: none;
        }

        th.th_cpb-action-header,
        td.cap-info-table__column-actions {
          border-left: none;
          width: 40px;
        }

        td:first-child {
          border-top-left-radius: 0;
        }
      }
    }
  }

  cpb-list-renderer-paginator {
    div.pagination__changer {
      height: 30px !important;
    }

    .cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover,
    .cap-dropdown.p-dropdown {
      height: 28px;

      .p-dropdown-label {
        height: calc(28px - 2px);
      }
    }
  }
}

body .cap-info-table__column-header cap-input .p-inputgroup {
  margin-bottom: 0 !important;
}

body .p-inputgroup input.p-inputtext, body .p-inputgroup input.p-inputtext:hover, body .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error), body .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error) {
  height: $cap-input-height !important;
}

body .table-filters-items .table-filter-row .filter .filter-icon {
  top: 14px !important;
}

.cap-cell-tooltip-container span {
  min-height: 15px;
  padding-left: 1px;
}

.no-border-cell,
.th_no-border-cell {
  border-left: none !important;
  text-align: right;
  text-overflow: initial !important;
  width: 30px;

  div {
    display: none;
  }
}
