body .p-inputtext:enabled:hover:not(.p-state-error),
body .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error) {
  color: $body-color !important;
}

body .p-inputgroup input.p-inputtext.cap-rounded,
body .p-inputgroup input.p-inputtext:hover.cap-rounded,
body .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error).cap-rounded,
body .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error).cap-rounded {
  border-color: $border-color;
}

.cap-calendar.p-calendar.cap-rounded:hover input,
.cap-calendar.p-calendar.cap-rounded:hover input:enabled:hover:not(.p-state-error),
.cap-calendar.p-calendar.cap-rounded input,
.cap-calendar.p-calendar.cap-rounded input:enabled:hover:not(.p-state-error) {
  border-color: $border-color;
  height: $cap-input-height !important;
}

.password-input .display-password-toggle {
  height: $cap-input-height !important;
}

body .p-multiselect-trigger,
body .p-dropdown-trigger {
  height: $cap-dropdown-height;
  background-color: transparent !important;

  &-icon.pi {
  font-size: 0.8rem !important;
  }
}
