html,
body {
  font-size: 15px;
}

h1 {
  font-weight: 600;
  font-size: 1.5rem;
  color: $default-font-color;
}

body .p-component {
  font-family: $font-family !important;
}
