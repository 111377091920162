@import 'abstracts';
@import 'emendis.theme';
@import 'styles';

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover .p-inputswitch-slider,
body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
  background: $green;
}

cap-login cap-validator {
  color: $cap-danger;
}

app-basic-layout cap-validator {
  display: none;
}

body .p-inputswitch {
  span.p-inputswitch-slider:before {
    margin-top: -7px;
  }
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  margin-top: -7px;
}

.login-wrapper .content-wrapper .content-left div.logo {
  height: 300px;
}
