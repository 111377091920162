body p-picklist .p-picklist {
  .p-picklist-list-wrapper {
    border: 1px solid $border-color;
    border-radius: 3px;
  }

  .p-picklist-list {
    max-height: 300px;
  }

  .p-picklist-filter-container {
    border: none;

    .p-picklist-filter-input {
      border-radius: 25px;
      border-color: $border-color;
      padding-left: 40px;
    }

    .p-picklist-filter-icon {
      left: 15px;
      right: auto;
    }
  }

  .p-picklist-header {
    margin: 5px;
    padding: 5px;
    border-bottom: 1px solid $border-color;

    .p-picklist-title {
      color: var(--black);
      font-weight: 600;
      font-size: 1rem;
    }
  }

  .p-picklist-list {
    padding: 5px;
    border: none;

    .p-picklist-item {
      background: $gray-light;
      padding: 10px;
      font-weight: 600;
      color: #5f6062;
      border-radius: 3px;
      border: 1px solid #ddd;
      margin-bottom: 5px;

      &.p-highlight {
        color: $white;
        background: var(--cap-primary-color)
      }
    }
  }

  .p-picklist-buttons {
    > button {
      color: var(--cap-primary-color);
      background-color: $white;
      border: 1px solid var(--cap-primary-color);
      border-radius: 5px;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}
