.dialog-open {
  .p-dialog-mask {
    background-color: rgba($gray-dark, 0.1);
    backdrop-filter: blur(2px);
  }

  .p-dialog {
    min-width: 40vw;
    max-width: 50vw;

    &.overflow-visible .p-dialog-content {
      overflow-y: visible;
    }

    &.dialog-md {
      min-width: 600px;
    }

    .p-dialog-header {
      background: $primary-gradient;
      color: $white;
      justify-content: flex-start;

      .p-dialog-title {
        flex: 1;
        text-align: center;
        font-size: 1.39rem;
        font-weight: 600;
        padding: 1.31rem;
      }

      .p-dialog-header-icons {
        padding-right: 1rem;

        .p-dialog-header-icon {
          color: $white;
        }
      }
    }

    .p-dialog-content {
      padding: 5px 31px 35px;
      font-size: 1rem;
    }

    .dialog-layout--content {
      border-bottom: 1px solid $border-color;
      color: $gray-dark;
      padding: 15px 0 20px;

      .cpb-grid {
        padding: 0;
      }

      cpb-form-renderer .cbp-default-input-wrapper.form-group.position-before .field-wrapper {
        padding-right: 0;
      }
    }

    .dialog-layout--footer {
      padding-top: 30px;

      .buttons {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .p-confirm-dialog {
    .p-dialog-content {
      margin: 12px 150px 25px;
      justify-content: center;
    }

    .p-dialog-footer {
      border-top: 1px solid $border-color;
      margin: 0 50px;
      padding: 35px 0;
      font-size: 1rem;

      button {
        margin-right: 0;
      }
    }
  }
}
