cap-login  {
  form cap-input .form-element {
    margin-bottom: 10px;
  }
}


app-login {
  cap-validator {
    display: none;
  }
}
