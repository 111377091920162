cap-card.hide .card {
  .card__content {
    padding: 0;
  }
}

cap-card .card {
  padding: 0;
  border-radius: 3px;
  box-shadow: none;
  border: none;

  .card__header {
    background: $primary-gradient;
    color: $white;
    border-radius: 3px 3px 0 0;
    padding: 10px $card-horizontal-header-padding;

    > .row {
      align-items: center;
    }

    .header__cta {
      .cap-button {
        color: $white;
        background-color: transparent;
        border: 0;
        border-radius: 0;
        padding: 0;
        padding-left: 20px;
        font-weight: 400;
        font-size: 0.875rem;
        max-height: 20px;

        .p-button-label {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    .header__title {
      font-weight: bold;

      h2 {
        font-size: 0.875rem;
        font-weight: 700;
      }
    }
  }

  .card__content {
    padding: 15px $card-horizontal-header-padding;
    position: relative;

    .card-buttons {
      display: flex;
      justify-content: flex-end;

      & > cap-button:not(:last-child) {
        .cap-button {
          margin-right: 10px;
        }
      }
    }

    &.content--gutter {
      margin-top: 0;
    }
  }
}

.cap-button.round {
  max-height: $cap-input-height;
  white-space: nowrap;
}
