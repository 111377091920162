/* You can add global styles to this file, and also import other style files */
@import './styles/main.scss';

:root {
  --text-color-4: #fff;
}

html,
body {
  height: 100%;
  overflow-y: hidden;
  background-color: $gray-light;
}