cap-sidebar-menu .cap-sidebar {
  min-height: unset;
  height: unset;
  position: fixed;
  left: 0;
  top: $total-header-height;
  bottom: 0;
  width: $sidebar-width;
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  li {
    color: #7a7a7a;
    font-weight: 600;
    padding: 14px 18px 14px 22px;

    li.active {
      background-color: #fafafa;
    }
  }

  &__brand {
    padding: 15px 0 0 0;
  }

  &__menus {
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow-y: auto;
    flex: 1;
  }
}
