@import 'abstracts';

$font-path: '../../assets/fonts/SourceSansPro';
$font-name: 'SourceSansPro'; /* stylelint-disable-line value-keyword-case */

@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 100 1000;
  font-stretch: 25% 151%;
}

/* stylelint-disable */
@font-face {
  font-family: $font-name;
  font-weight: normal;
  font-style: normal;
  src: url('#{$font-path}/#{$font-name}-Regular.woff2') format('woff2'),
       url('#{$font-path}/#{$font-name}-Regular.woff') format('woff'),
       url('#{$font-path}/#{$font-name}-Regular.ttf') format('truetype');
}

@font-face {
  font-family: $font-name;
  font-weight: 600;
  src: url('#{$font-path}/#{$font-name}-SemiBold.woff2') format('woff2'),
  url('#{$font-path}/#{$font-name}-SemiBold.woff') format('woff'),
  url('#{$font-path}/#{$font-name}-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: $font-name;
  font-weight: 700;
  src: url('#{$font-path}/#{$font-name}-Bold.woff2') format('woff2'),
  url('#{$font-path}/#{$font-name}-Bold.woff') format('woff'),
  url('#{$font-path}/#{$font-name}-Bold.ttf') format('truetype');
}
