@import 'abstracts/variables';

cap-horizontal-navbar {
  nav.navbar {
    box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.1);

    a.nav-item {
      &:not(.active):hover {
        background-color: $menu-hover-color;
      }

      div.nav-item-overlay {
        background: $menu-active-color;
        opacity: 1;
        border: none;
      }
    }
  }
}

.header {
  // Important is needed for a bootstrap override
  box-shadow: none !important;
}

.navbar .user-menu__items li {
  margin: 5px !important;
}
