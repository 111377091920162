@import 'abstracts/variables';

cap-calendar {
  span.p-ripple.p-element.p-monthpicker-month {
    padding: 10px;
  }

  .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    height: $cap-input-height;
  }
}

body {
  .dynamic-calendar-filter .cap-calendar.p-calendar.cap-rounded:hover input,
  .dynamic-calendar-filter .cap-calendar.p-calendar.cap-rounded:hover input:enabled:hover:not(.p-state-error),
  .dynamic-calendar-filter .cap-calendar.p-calendar.cap-rounded input,
  .dynamic-calendar-filter .cap-calendar.p-calendar.cap-rounded input:enabled:hover:not(.p-state-error) {
    height: $cap-input-height;
  }

  .p-datepicker table th {
    text-align: center;
  }
}

cap-timepicker .content {
  display: flex;
}

table.p-datepicker-calendar {
  thead tr > th:nth-last-child(-n + 2) {
    color: var(--cap-primary-color);
  }
}
