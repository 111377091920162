body .p-chips > ul.p-inputtext .p-chips-token {
  background: $gray-light;
  color: $body-color;
  padding: 5px;
  border-radius: 15px;
  border: 1px solid $border-color;

  .p-chips-token-icon {
    margin-left: 5px;
  }
}

body .p-chips {
  display: block;

  ul {
    width: 100%;

    .p-chips-input-token {
      display: none;
    }
  }
}

body cap-chips .cap-chips.p-chips ul.p-inputtext,
body cap-chips .cap-chips.p-chips ul.p-inputtext:not(.p-disabled):hover {
  padding: 5px;
  width: $cap-chips-width-hover;
  border-color: $cap-chips-border-color-hover;
}
