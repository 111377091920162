body .p-toast.cap-toast {
  &.idle-toast {
    .p-toast-message.p-toast-message-warn {
      border-left-color: var(--cap-primary-color);

      .toast-icon {
        color: var(--cap-primary-color);
      }
    }

    .toast-summary {
      padding-bottom: .5rem;
      color: var(--cap-primary-color);
    }

    .toast-button {
      padding-top: 1rem;

      button {
        font-size: 1rem;
      }
    }
  }
}
