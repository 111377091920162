cap-file-upload {
  .prime-file-upload {
    border: 2px dashed $border-color;
    border-radius: 10px;
    height: unset;
  }

  .file-upload-content {
    display: flex;

    i {
      margin-right: 15px;
      margin-bottom: 0;
      opacity: 1;
      display: flex;
      color: $black;
      flex-direction: column;
      justify-content: center;
    }

    .file-upload-placeholder {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
