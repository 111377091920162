:root {
  --content-padding: 1.25rem;
  --inline-spacing: 0.5rem;
}

@import './abstracts';

/* Base */
@import './base/reset';
@import './base/alignment';
@import './base/fonts';
@import './base/typography';

/* Vendor */
@import './vendor/bootstrap';
@import './vendor/prime';
@import './vendor/capturum-ui';
@import './vendor/font-awesone';

/* Components */
@import 'components/paginator';
@import 'components/dialog';
@import 'components/confirm-dialogs';
@import 'components/list-renderer';
@import 'components/form-renderer';
@import 'components/card';
@import 'components/filters';
@import 'components/sidebar';
@import 'components/info-table';
@import 'components/navbar';
@import 'components/pick-list';
@import 'components/dynamic-dialog';
@import 'components/file-upload';
@import 'components/scrollbar';
@import 'components/chips';
@import 'components/calendar';
@import 'components/inputs';
@import 'components/buttons';
@import 'components/login';
