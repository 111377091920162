@import 'abstracts';

$square-filter-border-radius: 3px;

body .cap-filters {
  .cap-dynamic-input-filter .p-inputgroup input.p-inputtext,
  .cap-dynamic-input-filter .p-inputgroup input.p-inputtext:enabled:focus:not(.p-state-error).cap-rounded,
  .cap-dynamic-input-filter .p-inputgroup input.p-inputtext:enabled:hover:not(.p-state-error).cap-rounded,
  .cap-dynamic-input-filter .p-inputgroup input.p-inputtext:hover:not(.p-state-error).cap-rounded:hover {
    padding-left: 35px;

    &.populated {
      padding-left: 38px;
      &:hover {
        padding-left: 38px;
      }

      &:focus-within {
        padding-left: 38px;
      }
    }
  }

  .dynamic-dropdown-filter .delete-filter {
    right: 33px;
  }

  .cap-dynamic-filter-layout {
    .p-multiselect-label.p-placeholder,
    .p-dropdown-label.p-placeholder {
      font-size: 1rem;
      color: $placeholder-color;
    }

    .cap-multi-select.p-multiselect,
    .cap-dropdown.p-dropdown,
    .cap-dropdown.p-dropdown:not(.p-disabled).p-focus,
    .cap-multi-select.p-multiselect:not(.p-disabled):hover,
    .cap-dropdown.p-dropdown:not(.p-disabled):hover {
      .p-dropdown-label,
      .p-multiselect-label {
        height: 100%;
      }
    }

    .p-multiselect-trigger-icon {
      font-size: 0.7rem;
    }

    p-calendar .cap-calendar input {
      padding-right: 30px;
    }

    label {
      white-space: nowrap;
    }

    .delete-filter {
      top: 45%;

      i.fa-trash-alt {
        color: var(--primary-color);
      }
    }
  }
}

.square-filter {
  label {
    font-weight: 600;
    margin: 0;
    margin-bottom: 5px;
  }

  .cap-multi-select.cap-rounded.p-multiselect.p-component {
    border-radius: $square-filter-border-radius;
    border: 1px solid $border-color;

    .p-muliselect-trigger-icon {
      font-size: 0.8rem;
    }
  }

  .cap-dropdown.cap-rounded.p-dropdown.p-component {
    border-radius: $square-filter-border-radius;
    border: 1px solid $border-color;

    .p-dropdown-trigger-icon {
      font-size: 0.8rem;
    }
  }

  .dynamic-calendar-filter .cap-calendar.cap-rounded.p-calendar input[type=text].p-inputtext.p-component {
    border-radius: $square-filter-border-radius;
    border: 1px solid $border-color;
  }
}

.cap-dynamic-filter-layout .cap-dynamic-filter-layout-content {
  .filter-icon {
    transform: translateY(-50%);

    i {
      opacity: .25 !important;
    }
  }
}

cap-dynamic-filters {
  .cap-filters {
    justify-content: space-between;

    .filter {
      max-width: 250px;
      min-width: 250px;
    }

    .filter-list {
      width: 100%;
    }

    .filter-button {
      .cap-button.tertiary:not(:disabled) {
        background-color: $white !important;
        border: 1px solid var(--cap-primary-color) !important;
        font-weight: 600;
        min-width: 170px;
      }

      .refresh-filter-button {
        display: none;
      }
    }
  }
}

.dynamic-calendar-filter .p-calendar {
  &.p-calendar-w-btn .p-datepicker-trigger.p-button {
    display: none;
  }

  input[type=text].p-inputtext.p-component {
    border-radius: 30px;
    border: 1px solid $border-color;

    &:hover {
      border-right: 1px solid $border-color;
    }
  }
}

cap-dynamic-range-filter .cap-dynamic-filter-layout {
  .delete-filter {
    right: 33px;
  }

  .range-filter-button {
    padding-left: 35px;
    padding-top: 7px;

    .filter-placeholder {
      color: $placeholder-color;
    }

    i {
      margin-top: 0;
    }
  }
}

.secondary.round {
  font-weight: 600;
}

.cap-rounded.cap-multi-select.p-multiselect,
.cap-rounded.cap-dropdown.p-dropdown,
.cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled).p-focus,
.cap-rounded.cap-multi-select.p-multiselect:not(.p-disabled):hover,
.cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled):hover {
  border-color: $border-color;
  height: $cap-input-height !important;


  .p-multiselect-trigger {
    height: 25px;
    font-size: 0.8rem;
  }
}

body .dynamic-multiselect-filter cap-multi-select p-multiselect.p-element .cap-multi-select .p-multiselect-label {
  margin-right: 0;
}

.range-filter-button {
  border-color: $border-color;
}
