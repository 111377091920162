body .p-dialog {
  border-radius: 5px;
  border: none;
  min-width: 425px;
  background: $white;

  &.p-confirm-dialog {
    .p-dialog-header {
      padding: 10px;
    }
  }

  .p-dialog-header {
    background: $primary-gradient;
    color: $white;
    font-weight: bold;
    font-size: 1.1rem;
    border-radius: 5px 5px 0 0;

    .p-dialog-header-icons span {
      color: $white;
      font-size: 1.2rem;
    }
  }

  .p-dialog-content {
    margin: 0 1.25em 0 1.25em;
    border: none;
    border-radius: 0 0 5px 5px;
    padding: 1.25em 0 1.25em 0;
  }

  .p-dialog-footer {
    border: none;
    margin-top: 1px;

    p-footer {
      display: flex;
      justify-content: space-between;
    }
  }
}
