/* General colors */
$primary: #d50232;
$primary-light: #f7fcfe;
$liver: #4f5152;
$black: #333;
$red: #ff3b30;
$secondary-blue: #028cc7;
$chateau-green: #35c44d;

/* Fonts */
$font-light: opensans light;
$font-regular: opensans regular;
$font-semi-bold: opensans semibold;
$font-bold: opensans bold;

/* Spacing */
$mobile-spacing: 15px;
$dashboard-page-padding: 40px;

/* General */
$body-color: #333;
$white: #fff;
$default-font-color: $black;
$font-family: 'Open sans';
$border-color: #ddd;

/* General sizes */
$nav-height: 30px;
$total-header-height: 150px;

/* General */
$gray-light: #f9f9f9;
$gray-dark: #333;
$body-color: #5f6062;
$dark-lava: #333;
$white: #fff;
$platinum: #e6e6e6;
$secondary-gray-dark: #7a7a7a;

$cap-input-height: 30px;
$primary-gradient: linear-gradient(0deg, rgb(33, 164, 225) 0%, rgb(84, 203, 252) 85%, rgb(127, 216, 253) 100%);
$basic-layout-padding-vertical: 40px;
$basic-layout-padding-horizontal: 50px;
$sidebar-width: 250px;
$table-fade-cell-color: #f7fbfd;
$table-cell-delimeter-color: #028cc7;
$cap-primary-color-rgb: rgb(43, 168, 226);
$menu-active-color: #f9f9f9;
$menu-hover-color: rgba($cap-primary-color-rgb, 0.05);
$card-horizontal-header-padding: 15px;
$placeholder-color: #666;
$divider-color: #dedede;

$cap-checkbox-state-active-border-color: var(--cap-primary-color);
$cap-dropdown-height: $cap-input-height;
$cap-dropdown-label-container-height: $cap-input-height;
$cap-calendar-input-height: $cap-input-height;
$cap-dropdown-multiselect-label-height: unset;
