.border-columns.row {
  margin: 0;
}

.border-columns > .border-column:not(:last-child) {
  border-right: 1px solid $border-color;
}

.border-column {
  padding: 0 45px;
}

formly-group {
  padding: 15px;
  border-radius: 3px;
}

cpb-form-renderer cpb-label-input {
  label {
    margin: 0 0 15px 0;
  }
}

cpb-form-renderer .cpb-readonly .form-invalid-feedback {
  display: none;
}

.cbp-default-input-wrapper.position-before,
.checkbox-wrapper.position-before .checkbox-wrapper-container {
  .label-wrapper label {
    margin-bottom: 1.2em;
  }
}

.cpb-form-render {
  color: #333;
}

cpb-form-renderer {
  .cbp-default-input-wrapper.form-group.position-before,
  .checkbox-wrapper.form-group.position-before .checkbox-wrapper-container {
    .label-wrapper {
      flex: 0 1 30%;
      max-width: 30%;

      label {
        margin-bottom: 0;
      }
    }

    .field-wrapper {
      padding: 0 5px;
      max-width: 70%;
    }
  }
}

.readonly-container {
  min-height: 25px !important;
  margin: 0;
}


body .p-inputgroup input.p-inputtext[readonly] {
  padding-left: 0;
  padding-top: 0;
  pointer-events: none;
}

span.cpb-required-indicator {
  color: var(--cap-primary-color);
}

cap-editor quill-editor .ql-container {
  height: 50%;
}

cpb-readonly-container {
  .readonly-container {
    .multiselect-list__readonly {
      height: unset;
    }
  }
}

body .cbp-default-input-wrapper.form-group {
  margin: 0.4rem 0;
}

cpb-dropdown-input .cap-dropdown.p-dropdown .p-dropdown-clear-icon {
  height: 22px;
  width: 22px;
}

cap-editor quill-editor .ql-container {
  overflow: hidden;
}

.cap-dropdown.p-dropdown .p-dropdown-trigger,
.cap-dropdown.p-dropdown .p-multiselect-trigger,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover .p-dropdown-trigger,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover .p-multiselect-trigger,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus .p-dropdown-trigger,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus .p-multiselect-trigger,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover .p-dropdown-trigger,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover .p-multiselect-trigger,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus .p-dropdown-trigger,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus .p-multiselect-trigger,
.cap-multi-select.p-multiselect .p-dropdown-trigger, .cap-multi-select.p-multiselect .p-multiselect-trigger,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover .p-dropdown-trigger,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover .p-multiselect-trigger,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus .p-dropdown-trigger,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus .p-multiselect-trigger,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover .p-dropdown-trigger,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover .p-multiselect-trigger,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus .p-dropdown-trigger,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus .p-multiselect-trigger {
  border-left: none;
}

cpb-form-renderer .cbp-default-input-wrapper .form-invalid-feedback {
  min-height: 0;
}

.p-dropdown.p-component.p-disabled {
  background: $gray-light;

  .p-dropdown-label {
    background: $gray-light;
  }
}
